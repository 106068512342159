@import "../../utils/commons.scss";

.container {
  width: 100%;
  margin-top: 2rem;
  .tableContainer {
    // width: var(--fixed-width);

    // border-radius: 10px !important;
    // overflow: hidden;
    min-height: 500px;
  }

  .header {
    @extend .flexRow;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 1rem;

    > button {
      min-width: 180px;
    }

    > div {
      @extend .flexRow;
      width: 100%;
      justify-content: flex-end;
      button {
        margin-left: 1rem;
      }
    }
  }
}
.dialog {
  > div:nth-of-type(3) > div {
    background: var(--clr-bg-dark) !important;
  }
  header {
    background: var(--clr-primary);
  }
}
.addNewArtist {
  position: relative;
  //   background-color: var(--clr-bg-dark);
  // margin-top: var(--nav-height);
  width: var(--fixed-width);
  margin: 2rem auto;
  margin-bottom: 1rem;

  form {
    background-color: #1f2226;
    border-radius: 20px;
    // margin: 3rem 4rem;
    padding: 3rem 4rem;
    @extend .flexCol;
  }

  .formSection {
    margin: 2rem 0;

    .header {
      width: -moz-fit-content;
      width: fit-content;
      @extend .flexRow;
      margin-bottom: 1rem;
    }

    .sectionNumber {
      @extend .flexRow;
      justify-content: center;
      padding: 1rem;
      width: 50px;
      height: 50px;
      border: 2px solid #ffffff;
      border-radius: 50%;
      margin-right: 1rem;
      color: white;
    }

    .children {
      @extend .flexRow;
      justify-content: flex-start;
      flex-wrap: wrap;

      > * {
        margin-right: 1rem;
      }
    }

    .sectionName {
      font-family: "Gilroy-500", sans-serif;
      color: white;
    }
  }

  .personInContact {
    margin-top: 3rem;
    width: 100%;
    display: grid;
    grid-template-columns: 350px 350px;
  }

  .inputs {
    margin: 0 60px;
    @extend .flexCol;
    align-items: flex-start;
    // flex-wrap: wrap;
  }

  .radioButtonGroup {
    @extend .flexRow;
    align-items: flex-end;
    width: -moz-fit-content;
    width: fit-content;

    > * {
      margin-right: 1rem;
    }

    > :last-child {
      margin-left: 2rem !important;
    }
  }
}
