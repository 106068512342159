@import "../../utils/commons.scss";

.container {
  position: relative;
  background: var(--clr-bg-dark);
  color: white;
  min-height: 100vh;
  padding: 0 calc( var(--sidemenu-width) + 10px);
  padding-top: calc( var(--nav-height ) + 4px );
  width: 100%;
}
